import React, {useState} from 'react'
import CategoryTable from '../../Components/Category/CategoryTable'
import CreateCategory from '../../Components/Category/CreateCategory'
import Button2 from '../../Components/SelectValue/Button2'

function Category() {
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
  return (
    <div>
        <CreateCategory
            open={open}
            handleClose={handleClose}
        />
        <h1 className='text-black text-4xl font-[800]'>List of Categories</h1>
        <div className=' flex justify-end  my-4'>
            <Button2
                name='Create Category'
                onClick={handleOpen}
            />
        </div>
        <CategoryTable/>
    </div>
  )
}

export default Category