import React from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
function Button2({name, onClick}) {
  return (
    <>
     <button 
      className=' bg-[#27AE61] px-4 py-4 border-none rounded-md text-white'
      onClick={onClick}
      type='submit'
     >{name} <AddCircleOutlineIcon/> </button>   
    </>
  )
}

export default Button2