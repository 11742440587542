import React, { useState} from 'react'
import Box from '@mui/material/Box';
import swal from 'sweetalert';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import postService from '../../Services/post-services';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../SelectValue/FormikControl'
import Button from '../SelectValue/Button'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

function UpdateTitle({open, handleClose, id, name}) {

    const initialValues = { 
        name: name
      }
      const validationSchema = Yup.object({
        name:Yup.string().required('Required'),
      
        
      })
      const onSubmit = values => {
        const {name} = values
        console.log('Form data', values)
        console.log('Saved data', JSON.parse(JSON.stringify(values)))
        postService.titleUpdate(name, id).then(
            (response) => {
                console.log(response.data)
                swal("Title created Successfully")
                  .then((value) => {
                    window.location.reload()
                  });
               
              },
              (error) => {
                
                  return  swal('Creation failed')
                  .then((value) => {
                    window.location.reload()
                  });
                }
          )
      }
   
    return (
      <div>
        
        <Modal
          open={open}
          
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
  
        >
          <Box sx={style} className=' shadow-lg rounded-md'>
            <Typography id="modal-modal-title" className='' variant="h6" component="h2">
              <div className=' flex justify-between'>
                  <h1 className=' text-gray-400'>Update title</h1>
                 <div onClick={handleClose}>
                 <HighlightOffOutlinedIcon />
                 </div> 
              </div>
            </Typography>
            

           
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    enableReinitialize={true}
                    >
                    {formik => (
                    <Form>
                        <div className=' my-3 grid  gap-4'>
                        <FormikControl
                            control='input'
                            type='text'
                            label='Title'
                            placeholder= 'Enter title'
                            name='name'
                          />
                       
                        </div>
                      <Button
                        name='Submit'
                      />
                       
                      
                     
                        
                    </Form>
                    )}
                 </Formik>
             
            </Typography>
            
         
          </Box>
        </Modal>
      </div>
  )
}

export default UpdateTitle