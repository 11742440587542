import React from 'react'
import LoginForm from '../Components/Login/LoginForm'
import Topsec from '../Components/Topsec'

function Login() {
  return (
    <div className=' p-2'>
        <Topsec
           subTitle='Sign in'
        />
        <LoginForm/>
    </div>
  )
}

export default Login