import React, {useState} from 'react'
import CreateNominee from '../../Components/Nominees/CreateNominees'
import NomineesTable from '../../Components/Nominees/NominessTable'
import Button2 from '../../Components/SelectValue/Button2'

function Nominees() {
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
  return (
    <div>
        <CreateNominee
            open={open}
            handleClose= {handleClose}
        />
         <h1 className=' text-black text-4xl mb-2 font-[800]'> Nominees Detials</h1>
         <div className=' flex justify-end mb-5'>
            <Button2
                name='Add Nominees'
                onClick={handleOpen}
            />
         </div>
         <NomineesTable/>
    </div>
  )
}

export default Nominees