import React,{useEffect, useState} from 'react'
import getServices from '../../Services/get-services'
function TopSec() {
    const[vote, setVote] = useState('')
    const [nominees, setNominees] = useState('')

    useEffect(() =>{
        getServices.voteCount().then(
          (response) => {
            setVote(response.data.total_votes);
            console.log(response.data)
            
          },
          (error) => {
           
    
              setVote('0');
          }
        )
        getServices.nomineesCount().then(
            (response) => {
              setNominees(response.data.total);
              console.log(response.data)
              
            },
            (error) => {
              
      
                setNominees('0');
            }
          )
      }, [])
  return (
    <div  className=' grid md:grid-cols-2 gap-7'>
            <div className=' p-5  bg-[#C0EBD3] rounded-lg'>
                <h1 className=' text-black text-center mb-2 text-4xl'>Total Vote</h1>
                <p className=' text-center text-2xl'>{vote}</p>
            </div>
            <div className=' p-5  bg-[#FCDDB6] rounded-lg'>
                <h1 className=' text-black text-center mb-2 text-4xl'>Total Nomineees</h1>
                <p className=' text-center text-2xl'>{nominees}</p>
            </div>
    </div>
  )
}

export default TopSec