import React from 'react'
function Topsec({subTitle}) {
  return (
    <div className=' bg relative'>
       
       <h1 className=' absolute bottom-20 left-5 text-white text-3xl md:text-5xl'>Jasline | Entertainment Admin Page </h1>
       <p className=' absolute bottom-10 left-5 text-white'>{subTitle} </p>
    </div>
  )
}

export default Topsec