import React from 'react'
import TopSec from '../../Components/Dashboard/TopSec'
import Votes from '../../Components/Dashboard/Votes'

function MainDashboard() {
  return (
    <div>
        <h1 className=' text-4xl font-[800] mb-3'>Jasline Admin</h1>
        <TopSec/>
        <Votes/>
    </div>
  )
}

export default MainDashboard