import React, {useEffect, useState} from 'react'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import getServices from '../../Services/get-services';

function SelectCategory({value, onChange, bool}) {
    const[category, setCategory] = useState([])

    useEffect(()=>{
        getServices.getCategoty().then(
            (response) => {
                
              setCategory(response.data.data);
              
              console.log(response.data.data)
              
            },
            (error) => {
              setCategory([])
            }
          )
    }, [])

  return (
    <div className={bool ? 'w-full' :' w-32'}>
    <FormControl fullWidth size={!bool &&"small"}>
        
        <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        placeholder='Select plate'
        onChange={(e) =>onChange(e.target.value)}
        >
        {category.map((i) =>(
          <MenuItem key={i.id} value={i.name}>{i.name}</MenuItem>
           
        ))}
        
        </Select>
        </FormControl>
    </div>
  )
}

export default SelectCategory