import axios from "axios";

 const API = "https://apis.jaslineentertainment.com/api"
const token =  localStorage.getItem('user')

const voteCount = () =>{
    return axios.get(API + `/get/count_votes.php?token=${token}`)
}

const nomineesCount = () =>{
    return axios.get(API + `/get/count_nominees.php?token=${token}`)
}

const getNominees = (id) =>{
    return axios.get(API + `/get/get_nominees.php?title_id=${id}`)
}
const getVote = () =>{
    return axios.get(API + `/get/get_votes.php?token=${token}`)
}
const getCategoty = () =>{
    return axios.get(API + `/get/get_categories.php?token=${token}`)
}
const getTitle = () =>{
    return axios.get(API + `/get/get_titles.php?token=${token}`)
}
const getAllNominees = () =>{
    return axios.get(API + `/get/get_all_nominees.php?token=${token}`)
}

const getServices = {
    voteCount,
    nomineesCount,
    getNominees,
    getVote,
    getCategoty,
    getTitle,
    getAllNominees
}

export default getServices;