import React from 'react'
import Input from './InputField'
import PasswordField from './PasswordField'
import Select from './Select'
import Textarea from './TextArea'


function FormikControl(props) {
    const { control, ...rest } = props
  switch(control){
    case 'input':
        return <Input {...rest}/>
    case 'textarea':
        return <Textarea {...rest}/>
    case 'select':
        return <Select {...rest}/>
    case 'password':
         return <PasswordField {...rest}/>
        default:
    return null
  }
}

export default FormikControl