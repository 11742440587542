
import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import getServices from '../../Services/get-services';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateNominees from './UpdateNominees';
import DeleteNominees from './DeleteNominees';
import SearchButton from '../SelectValue/SearchButton';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
const columns = [
  { id: 'no', label: 'No', minWidth: 10 },
  { id: 'name', label: 'Name', minWidth: 100 },
  { id: 'nick', label: 'Nickname', minWidth: 100 },
  { id: 'category', label: 'Category', minWidth: 100 },
  { id: 'vote', label: 'No of vote', minWidth: 10 },
  { id: 'update', label: 'Update', minWidth: 20 },
  { id: 'del', label: 'Delete', minWidth: 20 },
  
];



export default function NomineesTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [open, setOpen] =useState(false)
    const [open1, setOpen1] =useState(false)
    const [id, setId] = useState()
    const [search, setSearch] = useState('')
    const [ titleId, setTitleId] = useState('')
    const [name, setName] = useState('')
    const [nickname, setNickname] =  useState('')
    const handleClose = () => setOpen(false)
    const handleClose1 = () => setOpen1(false)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [detials, setDetails] = useState([]);
    useEffect(() =>{
        getServices.getAllNominees().then(
          (response) => {
            setDetails(response.data.nominies);
            console.log(response.data)
            
          },
          (error) => {
           
    
              setDetails([]);
          }
        )
       
      }, [])

      const filteredNominees = detials.filter(
        person => {
          return (
            person
            .name
            .toLowerCase()
            .includes(search.toLowerCase()) ||
            person
            .nick_name
            .toLowerCase()
            .includes(search.toLowerCase())
            ||
            person
            .category_name
            .toLowerCase()
            .includes(search.toLowerCase())
          );
        }
      );

  return (
    <div className=' mt-10'>
      <UpdateNominees
        open={open}
        handleClose={handleClose}
        name={name}
        id={id}
        titleid={titleId}
        nickname={nickname}
      />
      <DeleteNominees
        open={open1}
        handleClose={handleClose1}
        name={name}
        id={id}
      />
      <div className=' mb-4'>
      <SearchButton
        label='Search by name, Nickname, Category'
        onChange={setSearch}
       /> 
      </div>
      <div className='flex justify-end'>
            <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className='p-2 rounded-xl bg-[#27AE61] mt-1 mr-2 underline underline-offset-1 text-white'
                    table="donation-table"
                    filename='nominationdetails'
                    sheet="tablexls"
                    buttonText="DownLoad Data"/>
            </div>
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table" id='donation-table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
          {filteredNominees
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                      <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.nick_name}</TableCell>
                <TableCell align="left">{row.category_name}</TableCell>
                <TableCell align="left">{row.votes_count}</TableCell>
                <TableCell align="left"><BorderColorIcon className=' text-blue-700'
                    onClick={() =>{
                        setOpen(true)
                        setName(row.name)
                        setId(row.id)
                        setTitleId(row.category_id)
                        setNickname(row.nick_name)
                    }}
                 /></TableCell>
                <TableCell align="left"><DeleteIcon className=' text-red-800'
                    onClick={() =>{
                        setOpen1(true)
                        setName(row.name)
                        setId(row.id)
                    }}
                /></TableCell>
              </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 600]}
        component="div"
        count={filteredNominees.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </div>
  );
}


 