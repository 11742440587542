import {BrowserRouter, Route,  Routes} from 'react-router-dom';
import Dashboard from './Pages/Dashboard';
import Login from './Pages/Login';
import ProtectedRoute from './Pages/Protected/ProtectedRoute';


function App() {
  return (
    <div>
       <BrowserRouter>
       <Routes>
        <Route path='/' element={<Login/>}/>
        <Route path='*' element={<ProtectedRoute><Dashboard/></ProtectedRoute>}/>
       </Routes>
       </BrowserRouter>
    </div>
  );
}

export default App;
