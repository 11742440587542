import React, {useState} from 'react'
import Button2 from '../../Components/SelectValue/Button2'
import CreateTitle from '../../Components/Title/CreateTitle'
import TitleTable from '../../Components/Title/TitleTable'

function Title() {
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
  return (
    <div>
        <CreateTitle
            open={open}
            handleClose={handleClose}
        />
        <h1 className=' text-black text-4xl font-[800]'> Vote Titles</h1>
        <div className=' flex justify-end  my-4'>
            <Button2
                name='Create Title'
                onClick={handleOpen}
            />
        </div>
        <TitleTable/>
    </div>
  )
}

export default Title