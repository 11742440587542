import React from 'react'
import Box from '@mui/material/Box';
import swal from 'sweetalert';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import postService from '../../Services/post-services';
import Button from '../SelectValue/Button'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

function DeleteCategory({open, handleClose, id, name}) {

    
      const onSubmit =() => {
        
        postService.deleteCategory(id).then(
            (response) => {
                console.log(response.data)
                swal("Category Deleted Successfully")
                  .then((value) => {
                    window.location.reload()
                  });
               
              },
              (error) => {
                
                  return  swal(' Failed')
                  .then((value) => {
                    window.location.reload()
                  });
                }
          )
      }
   
    return (
      <div>
        
        <Modal
          open={open}
          
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
  
        >
          <Box sx={style} className=' shadow-lg rounded-md'>
            <Typography id="modal-modal-title" className='' variant="h6" component="h2">
              <div className=' flex justify-between'>
                  <h1 className=' text-gray-400'>Delete Category</h1>
                 <div onClick={handleClose}>
                 <HighlightOffOutlinedIcon />
                 </div> 
              </div>
            </Typography>
            

           
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <h1 className=' text-center text-2xl mb-5'> Are you sure you want to delete {id} {name}?</h1>
            <div className=' flex  justify-center gap-4'>
            <Button
              name='No'
              onClick={handleClose}
              />
               <Button
                name='Yes'
                onClick={onSubmit}
              />
            </div>
          
             
            </Typography>
            
         
          </Box>
        </Modal>
      </div>
  )
}

export default DeleteCategory