import axios from "axios";

const API = "https://apis.jaslineentertainment.com/api"

const login = (email, password) => {
    const post ={'email': email, 'password': password}
    return axios.post(API + '/post/login.php', post )
    .then((response) =>{
        if(response.data.status === "success"){
            localStorage.setItem('user', (response.data.token))
            
        }
        localStorage.setItem('error',response.data.error)
        return response.data;
    })
}


const logout = () => {
    localStorage.removeItem("user");
  };

const authService = {
    login,
    logout
}


export default authService;