import React,{useEffect, useState} from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Button from '../SelectValue/Button'
import {login} from '../../Slice/auth'
import { useDispatch, useSelector } from "react-redux";
import FormikControl from '../SelectValue/FormikControl'
import { useNavigate, Navigate } from 'react-router-dom'
import {clearMessage} from '../../Slice/message'
import Loading from '../SelectValue/Loading'
function LoginForm() {
    const navigate = useNavigate()
    const { isLoggedIn, isLoading, user } = useSelector((state) => state.auth);
    const { message } = useSelector((state) => state.message);
 
    const err = localStorage.getItem('error')
    const dispatch = useDispatch();
    let status = false

    useEffect(() => {
     
      dispatch(clearMessage());
      if(status){
        // navigate('/dashboard');
        window.location.href = '/dashboard'
      }
    }, [dispatch, status, navigate]);
    
    const initialValues = { 
        email: '',
        
        password: '',

      }
      const validationSchema = Yup.object({
        email:Yup.string().required('Required'),
       password: Yup.string().required('Required'),


        
      })
      const onSubmit = values => {
        
        // swal("Logged in Successfully");
        const{email, password} = values
        console.log('Form data', values.email)
        console.log('Saved data', JSON.parse(JSON.stringify(values)))
       
      dispatch(
        login({email, password}))
      .unwrap()
      .then((res) =>{
       
        if(user.response_code === '200'){
          localStorage.setItem('status', 'true')
        //   navigate('/dashboard');
         
        }
        
       
      })
      .catch(() => {
       
      });
      }

      if ( isLoggedIn) {
        return <Navigate to="/dashboard" />;
      }
    
  return (  <div className=' mt-10'>
            <Loading
              open={isLoading}
            />
                <div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    >
                    {formik => (
                       
                    <Form>
                    <div className=' grid md:grid-cols-6 gap-6'>
                    <div className=' col-span-2'>
                    <FormikControl
                            control='input'
                            type='text'
                            label='Email'
                            placeholder= 'Enter your email'
                            name='email'
                     /> 
                    </div>
                   
                     <div className=' col-span-2'>
                     <FormikControl
                            control='password'
                            label='Password'
                            placeholder= 'Enter your Password'
                            name='password'
                        /> 
                        </div> 
                        <div className=' md:mt-6 col-span-2'>
                        <Button
                            name='Log In'
                        />
                        </div>
                       
                         
                    </div>
                    </Form>
                    )}
                 </Formik>
                </div>
        
    </div>
  )
}

export default LoginForm