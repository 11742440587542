import  React, {useState} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Logo from '../../images/logo.png'
import SpeedIcon from '@mui/icons-material/Speed';
import ApartmentIcon from '@mui/icons-material/Apartment';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import { Link, NavLink, Route, Routes } from 'react-router-dom';
import MainDashboard from '../MainDashboard';
import Title from '../Title';
import Category from '../Category';
import Nominees from '../Nominees';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import LogoutIcon from '@mui/icons-material/Logout';
import Logout from '../Logout';
const drawerWidth = 240;



function Dashboard(props) {
    const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [sidebar, setSidebar] = useState([
    {
        name: 'Dashboard',
        icon: (
            <SpeedIcon />
        ),
        link: '/dashboard',
        id: 'dashbord',
    },
    {
        name: 'Title',
        icon: (
            <ApartmentIcon />
        ),
        link: '/title',
        id: 'title',
    },
   
    {
        name: 'Categories',
        icon: (
            <Diversity1Icon />
        ),
        link: '/category',
        id: 'category',
    },
   
    {
        name: 'Nominees',
        icon: (
            <EmojiEventsIcon />
        ),
        link: '/nominees',
        id: 'nominees',
    },
    {
      name: 'Sign out',
      icon: (
          <LogoutIcon style={{ color: 'red' }}  />
      ),
      link: '/logout',
      id: 'signout',
  },
  ])
  const drawer = (
    <div>
     <div className=' pt-2  bg-[#27AE61]  '>
        <img src={Logo} alt='logo' className=' mx-auto'/>
          </div>
     
      <List>
          {sidebar.map((text, index) => (
            <NavLink
             className={({isActive}) =>{
                            return( ' block p-5 pl-6 ' + (
                              !isActive ? ' block hover:bg-white' : ' bg-button-color text-white block'
                            ))
                        }}  
                      to={text.link}>
              {text.icon}
              <span className=' pl-3'>{text.name}</span>
           
            </NavLink>
           
            
          ))}
          
        </List>
      
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <div>
   
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={1}
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
         backgroundColor: 'white',
          
        
        }}
      >
      <div className=' flex justify-between'>
      <Toolbar>
      <IconButton
            
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
         
                
       
        </Toolbar>
            <div className=' flex flex-col justify-center p-2'>
            <div>
                <p className=' text-black inline-block mr-4'> Welcome back </p>
               
                    </div>
                    </div>
                 </div>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#27AE61' },
          }}
        >
          {drawer}
          

        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth,  backgroundColor: '#27AE61' },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
      <div className=' w-full' >
      <Routes>
                <Route path='/dashboard' element={<MainDashboard/>}/>
                <Route path='/title' element={<Title/>}/>
                <Route path='/category' element={<Category/>}/>
                <Route path='/nominees' element={<Nominees/>}/>
                <Route path='/logout' element={<Logout/>} />
                <Route path='*' element={<MainDashboard/>} />
            </Routes>
      </div>
        
      </Box>
    </Box>
    </div>
  )
}
Dashboard.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };

export default Dashboard