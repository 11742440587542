
import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import getServices from '../../Services/get-services';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateCategory from './UpdateCategory';
import DeleteCategory from './DeleteCategory';
import SearchButton from '../SelectValue/SearchButton';
const columns = [
  { id: 'no', label: 'No', minWidth: 170 },
  { id: 'name', label: 'Name', minWidth: 100 },
  { id: 'update', label: 'Update', minWidth: 20 },
  { id: 'del', label: 'Delete', minWidth: 20 },
  
];



export default function CategoryTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [open, setOpen] =useState(false)
    const [open1, setOpen1] =useState(false)
    const [id, setId] = useState()
    const [search, setSearch] = useState('')
    const [ titleId, setTitleId] = useState('')
    const [name, setName] = useState('')
    const handleClose = () => setOpen(false)
    const handleClose1 = () => setOpen1(false)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [detials, setDetails] = useState([]);
    useEffect(() =>{
        getServices.getCategoty().then(
          (response) => {
            setDetails(response.data.data);
            console.log(response.data)
            
          },
          (error) => {
           
    
              setDetails([]);
          }
        )
       
      }, [])

      const filteredCategory = detials.filter(
        person => {
          return (
            person
            .name
            .toLowerCase()
            .includes(search.toLowerCase()) )
        }
      );

  return (
    <div className=' mt-10'>
        <UpdateCategory
            open={open}
            handleClose={handleClose}
            id={id}
            name={name}
            titleid={titleId}
        />
        <DeleteCategory
            open={open1}
            handleClose={handleClose1}
            id={id}
            name={name}
        />
        <div>
          <SearchButton
            label='Searh by name ...'
            onChange={setSearch}
          />
        </div>
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
          {filteredCategory
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                      <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="left">{row.name}{row.id}</TableCell>
                <TableCell align="left"><BorderColorIcon className=' text-blue-700'
                    onClick={() =>{
                        setOpen(true)
                        setName(row.name)
                        setId(row.id)
                        setTitleId(row.title)
                    }}
                 /></TableCell>
                <TableCell align="left"><DeleteIcon className=' text-red-800'
                    onClick={() =>{
                        setOpen1(true)
                        setName(row.name)
                        setId(row.id)
                    }}
                /></TableCell>
              </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filteredCategory.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </div>
  );
}


 