
import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import getServices from '../../Services/get-services';
import SelectCategory from './SelectCat';
const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'nick', label: 'Nickname', minWidth: 100 },
  {
    id: 'cat',
    label: 'Category',
    minWidth: 110,
    align: 'left',
   
  },
  {
    id: 'vote',
    label: 'Number of Votes',
    minWidth: 30,
    align: 'left',
  
  },
  
];



export default function Votes() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [detials, setDetails] = useState([]);
  const [camName, setCamName]= useState('MOST POPULAR MALE')
    useEffect(() =>{
        getServices.getVote().then(
          (response) => {
            setDetails(response.data.nominies[camName]);
            console.log(response.data)
            
          },
          (error) => {
           
    
              setDetails([]);
          }
        )
       
      }, [camName])
  return (
    <div className=' mt-10'>

        <h1 className=' text-4xl text-black mb-4 font-[800]'>Vote details</h1>
        <div className=' flex justify-end'>
        <SelectCategory
          value={camName}
          onChange= {setCamName}
        />
        </div>
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
          {detials
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                      <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="left">{row.nick_name}</TableCell>
                <TableCell align="left">{row.category_name}</TableCell>
                <TableCell align="left">{row.votes_count}</TableCell>
               
              </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={detials.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </div>
  );
}


 