import axios from "axios";
const API = "https://apis.jaslineentertainment.com/api"
const token =  localStorage.getItem('user')

const createTitle = (name) =>{

    const value ={ "name": name, "token":token}

    return axios.post(API +'/post/add_title.php', value );
}
const titleUpdate = (name, id) =>{

    const value ={ "name": name,  "id":id, "token":token}

    return axios.patch(API +'/edit_title.php', value );
}
const titleDelete = ( id) =>{

    const value ={  "id":id, "token":token}

    return axios.patch(API +'/delete/delete_title.php', value );
}
const createCategory = (name, id) =>{

    const value ={ "name": name, "title_id": id, "token":token}

    return axios.post(API +'/post/add_category.php', value );
}
const updateCategory = (name, id, title_id,) =>{

    const value ={ "name": name, "title_id":title_id, "id": id, "token":token}

    return axios.patch(API +'/patch/edit_category.php', value );
}
const deleteCategory = (id,) =>{

    const value ={ "id": id, "token":token}

    return axios.delete(API +'/delete/delete_category.php', {data:value} );
}
const createNominees = (name,nickname, category_id) =>{

    const value ={"name": name,   "nick_name":nickname, "category_id": category_id, "token":token}

    return axios.post(API +'/post/add_nominee.php', value );
}
const updateNominees = (name, id, nick_name, category_id) =>{

    const value ={ "name": name, "nick_name":nick_name, "id": id, "category_id": category_id, "token":token}

    return axios.patch(API +'/patch/edit_nominee.php', value );
}
const deleteNominees = (id,) =>{

    const value ={ "id": id, "token":token}

    return axios.delete(API +'/delete/delete_nominee.php', {data:value} );
}
const postService ={
    createTitle,
    titleUpdate,
    titleDelete,
    createCategory,
    updateCategory,
    deleteCategory,
    createNominees,
    updateNominees,
    deleteNominees
}

export default postService